import avatar from "../Images/test.png";
import pricing_property from "../Images/property_pricing.webp";
import company_pricing from "../Images/i.webp";
import { BsLinkedin } from "react-icons/bs";
import { FaYoutube, FaTelegram, FaChartPie } from "react-icons/fa";
import { GrInstagram, GrMoney } from "react-icons/gr";
import { GiTakeMyMoney, GiReceiveMoney, GiProgression } from "react-icons/gi";
import { LiaUsersSolid } from "react-icons/lia";
import { LuBookOpenCheck } from "react-icons/lu";
import { SiOpenaccess } from "react-icons/si";
import { GoLaw } from "react-icons/go";
import how1 from "../Images/register.png";
import how2 from "../Images/expanse.png";
import how3 from "../Images/done.png";
export default {
    header_navbar: [
        {
            nav_liEN: "Home",
            nav_liRU: "Домой",
            nav_liTR: "Ev",
            route: "/",
        },
        {
            nav_liEN: "Products",
            nav_liRU: "Продукты",
            nav_liTR: "Ürünler",
            route: "/products",
        },
        {
            nav_liEN: "Benefits",
            nav_liRU: "Выгоды",
            nav_liTR: "Faydalar",
            route: "/benefits",
        },
        {
            nav_liEN: "How it works",
            nav_liRU: "Как это работает",
            nav_liTR: "Nasıl çalışır",
            route: "/how",
        },
        {
            nav_liEN: "Company",
            nav_liRU: "Компания",
            nav_liTR: "Şirket",
            route: "/company",
        },
    ],
    how_steps: [
        {
            url: how1,
            textEN: "Register your Spend.In account.",
            textRU: "Регистрируйте свои траты.В личном кабинете.",
            textTR: "Kayıt ol Spend.In hesap.",
        },
        {
            url: how2,
            textEN: "Fill in the list of your business expenses.",
            textRU: "Заполните список ваших деловых расходов.",
            textTR: "İşletme giderlerinizin listesini doldurun.",
        },
        {
            url: how3,
            textEN: "Done, let’s continue the work.",
            textRU: "Готово, давайте продолжим работу.",
            textTR: "Bitti, çalışmaya devam edelim.",
        },
    ],
    hero_text: [
        "Motto of Plain2Do is - 'Plain to See - Easy to Do' The phrase 'Plain to See - Easy to Do' suggests that something is clearly visible or evident and, as a result, is straightforward or easy to accomplish. It conveys the idea that the task or objective at hand is apparent and can be easily achieved without complications or difficulties.The phrase 'Plain to See' implies that something is obvious, clear, or easily discernible. It suggests that there are no hidden or complex elements involved, and the information or situation is readily apparent to anyone observing or analyzing it.On the other hand, 'Easy to Do' indicates that the task or action required is simple, uncomplicated, and can be accomplished without much effort or complexity. It implies that the process or steps involved are straightforward and user-friendly, making it easy for individuals to complete the task or achieve the desired outcome.Combining these two phrases, 'Plain to See - Easy to Do' conveys the idea that the solution or process being offered is readily visible and understandable, and the actions required to accomplish it are straightforward and hassle-free. It suggests that users will have a clear understanding of what needs to be done and will find it effortless to execute the necessary steps to achieve their goals.",
    ],
    success_page: {
        success_disadvantages: [
            {
                textEN: "Complex recording process due to every administrative file in a different place.",
                textTR: "Her yönetim dosyasının farklı bir yerde olması nedeniyle karmaşık kayıt işlemi.",
                textRU: "Сложный процесс регистрации из-за того, что каждый административный файл находится в другом месте.",
            },
            {
                textEN: "Need more effort to pay manually one by one by invoice because there is no payment accommodation.",
                textTR: "Ödeme konaklama olmadığı için fatura ile tek tek manuel ödeme yapmak için daha fazla çabaya ihtiyacınız var.",
                textRU: "Требуется больше усилий, чтобы оплачивать вручную один за другим по счету-фактуре, потому что нет возможности для оплаты.",
            },
            {
                textEN: "Manual data arranging needs a long time because the different months/years are not in the same place.",
                textTR: "Farklı aylar / yıllar aynı yerde olmadığı için manuel veri düzenlemenin uzun sürmesi gerekir.",
                textRU: "Для упорядочивания данных вручную требуется много времени, поскольку разные месяцы/годы находятся в разных местах.",
            },
        ],
        success_advantages: [
            {
                heading_textEN: "Features and Functionality: ",
                heading_textRU: "Особенности и функциональность: ",
                heading_textTR: "Özellikler ve İşlevsellik: ",
                text_descriptionEN:
                    "Plain2Do is offering unique features or functionalities that address specific pain points or provide added value to users.",
                text_descriptionRU:
                    "Plain2Do предлагает уникальные функции, которые решают конкретные проблемы или обеспечивают дополнительную ценность для пользователей.",
                text_descriptionTR:
                    "Plain2Do, belirli acı noktalarını ele alan veya kullanıcılara katma değer sağlayan benzersiz özellikler veya işlevler sunuyor.",
            },
            {
                heading_textEN: "User Experience and Interface: ",
                heading_textRU: "Пользовательский опыт и интерфейс: ",
                heading_textTR: "Kullanıcı Deneyimi ve Arayüz: ",
                text_descriptionEN:
                    "Plain2Do has a user-friendly and intuitive interface. We provide a seamless and enjoyable user experience to attract and retain customers.",
                text_descriptionRU:
                    "Plain2Do обладает удобным и интуитивно понятным интерфейсом. Мы предоставляем простой и приятный интерфейс для привлечения и удержания клиентов.",
                text_descriptionTR:
                    "Plain2Do kullanıcı dostu ve sezgisel bir arayüze sahiptir. Müşterileri çekmek ve elde tutmak için sorunsuz ve keyifli bir kullanıcı deneyimi sunuyoruz.",
            },
            {
                heading_textEN: "Customization and Scalability: ",
                heading_textRU: "Настраиваемость и масштабируемость: ",
                heading_textTR: "Özelleştirme ve Ölçeklenebilirlik: ",
                text_descriptionEN:
                    "Plain2Do products might be customized to meet specific business needs and scale as the organization grows. * (In Enterprise subscription plan)",
                text_descriptionRU:
                    "Продукты Plain2Do могут быть адаптированы для удовлетворения конкретных бизнес-потребностей и масштабироваться по мере роста организации. * (В корпоративном плане подписки)",
                text_descriptionTR:
                    "Plain2Do ürünleri, belirli iş ihtiyaçlarını karşılamak ve kuruluş büyüdükçe ölçeklendirmek için özelleştirilebilir. * (Kurumsal abonelik planında)",
            },
            {
                heading_textEN: "Integration Capabilities: ",
                heading_textRU: "Интеграционные возможности: ",
                heading_textTR: "Entegrasyon Yetenekleri: ",
                text_descriptionEN:
                    "Plain2Do products have seamless integration with other popular tools and platforms and can provide added convenience and efficiency for users.",
                text_descriptionRU:
                    "Продукты Plain 2 Do легко интегрируются с другими популярными инструментами и платформами и могут обеспечить дополнительное удобство и эффективность для пользователей.",
                text_descriptionTR:
                    "Düz 2 Do ürünleri, diğer popüler araçlar ve platformlarla sorunsuz entegrasyona sahiptir ve kullanıcılar için ek kolaylık ve verimlilik sağlayabilir.",
            },
            {
                heading_textEN: "Pricing and Value: Plain2Do",
                heading_textRU: "Ценообразование и ценность: Plain2Do",
                heading_textTR: "Fiyatlandırma ve Değer: Plain2Do",
                text_descriptionEN:
                    "Plain2Do offers competitive pricing models, flexible plans, and transparent pricing structures. We offer a strong value proposition in terms of the benefits and ROI provided.",
                text_descriptionRU:
                    "Plain2Do предлагает конкурентоспособные модели ценообразования, гибкие тарифные планы и прозрачные структуры ценообразования. Мы предлагаем выгодное предложение с точки зрения предоставляемых преимуществ и рентабельности инвестиций.",
                text_descriptionTR:
                    "Plain2Do, rekabetçi fiyatlandırma modelleri, esnek planlar ve şeffaf fiyatlandırma yapıları sunar. Sağlanan faydalar ve yatırım getirisi açısından güçlü bir değer teklifi sunuyoruz.",
            },
        ],
    },
    pricing_options: {
        options: [
            {
                pricing_textEN: "Workforce",
                pricing_img: company_pricing,
                text_bodyEN: "We offer reliable service to companies",
                text_bodyTR: "Firmalara güvenilir hizmet sunuyoruz",
                text_bodyRU: "Мы предлагаем надежный сервис компаниям",
                link: "pricing-company",
                workforce_features: [
                    {
                        img_icon: <GiTakeMyMoney />,
                        titleEN: "Forecasting and Budgeting",
                        titleTR: "Tahmin ve Bütçeleme",
                        titleRU: "Планирование и бюджетирование:",
                        textEN: "Create your workforce needs and budgets for each Project swiftly. Perform calculations in different currencies. Forecast your expenses in a Gantt Chart.",
                        textRU: "Быстро и легко создавайте бюджеты рабочей силы и планы для каждого вашего проекта. Составляйте расчеты в разных валютах. Прогнозируйте свои расходы в диаграмме Ганта.",
                        textTR: "Her proje için iş gücü ihtiyaçlarınızı ve bütçelerinizi hızlıca oluşturun. Farklı para birimlerinde hesaplamalar yapın. Tüm tahminleri Gantt Şeması'nda takiğ edin.",
                    },
                    {
                        img_icon: <LiaUsersSolid />,
                        titleEN: "Employee Management",
                        titleTR: "Çalışan Yönetim",
                        titleRU: "Управление сотрудниками",
                        textEN: "Centralized database for storing employee information, including personal details, job roles, and performance records. Control Job Pool for a list of planned, budgeted, and unassigned open job positions for a project.",
                        textRU: "Централизованная база данных для хранения информации о сотрудниках, включая личные данные, должностные роли и данные о показателях эффективности. Управление открытыми должностями по каждому проекту.",
                        textTR: "Çalışan özlük bilgilerini, iş görevleri ve performans kayıtlarını merkezi bir veritabanda saklayın. Proje için planlanan, bütçelenen ve atanmamış açık iş pozisyonlarının listesi için İş Havuzu kontrolü oluşturun.",
                    },
                    {
                        img_icon: <LuBookOpenCheck />,
                        titleEN: "Attendance Tracking",
                        titleTR: "Personel Devam Kontrol",
                        titleRU: "Табель учета",
                        textEN: "Tools for monitoring employee attendance, including clock-in/out functionalities, leave management, and absence tracking.",
                        textRU: "Инструменты для мониторинга посещаемости сотрудников, включая функции отслеживания времени входа/выхода, управление отпусками и отслеживание прогулов.Расчёт зарплаты: Автоматизированные расчеты заработной платы, которые обеспечивают своевременную выплату зарплат, калькуляция налогов и соблюдение нормативных актов.",
                        textTR: "Çalışanların giriş-çıkış saatlerinin kaydedilmesi, puantajların girilmesi, izin yönetimi ve devamsızlık takibi gibi konuları kolaylıkla yürütün.",
                    },
                    {
                        img_icon: <GiReceiveMoney />,
                        titleEN: "Payroll Processing",
                        titleTR: "Dahili Bordro Yönetimi",
                        titleRU: "Расчёт зарплаты",
                        textEN: "Automated payroll calculations that ensure timely salary disbursement, tax calculations, and compliance with local regulations.",
                        textRU: "Автоматизированные расчеты заработной платы, которые обеспечивают своевременную выплату зарплат, калькуляция налогов и соблюдение нормативных актов.",
                        textTR: "Zamanında maaş hesaplamarını ve ödemelerini, vergi hesaplamalarını ve yerel düzenlemelere uyumu sağlayan otomatik system kullanın.Raporlama ve Analitik: İK Performans ve diğer istatistiki bilgileri sağlayan kapsamlı raporlama aracidir, IK yöneticilerinin veri odaklı kararlar almasına yardımcı olur.Şirketiniz İçin Faydaları",
                    },
                    {
                        img_icon: <FaChartPie />,
                        titleEN: "Reporting and Analytics",
                        titleTR: "Raporlama ve Analitik",
                        titleRU: "Отчетность и аналитика",
                        textEN: "Comprehensive reporting tools that provide insights into workforce metrics, helping HR managers make data-driven decisions.Benefits for Your Company",
                        textRU: "Комплексные инструменты отчетности, которые предоставляют информацию о метриках рабочей силы, помогая HR-менеджерам принимать решения на основе данных Преимущества для Вашей компании Масштабируемость: Plain2Do Workforce позволяет компаниям легко масштабировать свои HR-операции по мере роста, accommodating больше пользователей и функций без значительных изменений в инфраструктуре.",
                        textTR: "İK Performans ve diğer istatistiki bilgileri sağlayan kapsamlı raporlama aracidir, IK yöneticilerinin veri odaklı kararlar almasına yardımcı olur.Şirketiniz İçin Faydaları",
                    },
                ],
                benefits: [
                    {
                        img_icon: <GiProgression />,
                        titleEN: "Scalability",
                        titleTR: "Ölçeklenebilirlik",
                        titleRU: "Масштабируемость",
                        textEN: "The Plain2Do Workforce allows your company to scale HR operations easily as you grow, accommodating more users and features without significant infrastructure changes.",
                        textRU: "Plain2Do Workforce позволяет компаниям легко масштабировать свои HR-операции по мере роста, accommodating больше пользователей и функций без значительных изменений в инфраструктуре.",
                        textTR: "Plain2Do Workforce, işletmelerin büyüdükçe İK işlemşerini kolayca ölçeklendirmesine olanak tanır; önemli altyapı değişiklikleri olmadan daha fazla kullanıcı ve özellik barındırabilir.",
                    },
                    {
                        img_icon: <GrMoney />,
                        titleEN: "Cost Efficiency",
                        titleTR: "Maliyet Verimliliği",
                        titleRU: "Снижение затрат",
                        textEN: "Reduces overhead costs associated with traditional HR systems by minimizing the need for extensive IT support and hardware investments.",
                        textRU: "Снижает накладные расходы, связанные с традиционными HR-системами, минимизируя необходимость в обширной ИТ-поддержке и инвестициях в оборудование.",
                        textTR: "Geleneksel insan kaynakları sistemleriyle ilişkili genel giderleri azaltarak kapsamlı IT desteği ve donanım yatırımı ihtiyacını en aza indirir.",
                    },
                    {
                        img_icon: <SiOpenaccess />,
                        titleEN: "Accessibility",
                        titleTR: "Erişilebilirlik",
                        titleRU: "Доступность",
                        textEN: "Being cloud-based means that HR managers and employees can access the system from anywhere, facilitating remote work environments.",
                        textRU: "Облачная основа позволяет HR-менеджерам и сотрудникам получать доступ к системе из любой точки, что облегчает удаленные рабочие условия.",
                        textTR: "Bulut tabanlı olması sayesinde IK yöneticileri ve çalışanlar sistemi her yerden erişebilir, uzaktan çalışma ortamlarını kolaylaştırır.",
                    },
                    {
                        img_icon: <GoLaw />,
                        titleEN: "Compliance Management",
                        titleTR: "Uyum Yönetimi",
                        titleRU: "Управление соблюдением норм",
                        textEN: "Helps your organization stay compliant with labor laws and regulations through automated updates and reporting features.",
                        textRU: "Помогает организациям оставаться в соответствии с трудовым законодательством и нормативными актами благодаря автоматическим обновлениям и функциям отчетности.",
                        textTR: "Otomatik güncellemeler ve raporlama özellikleri aracılığıyla şirketlerin iş kanunlarına ve düzenlemelere uymasına yardımcı olur.",
                    },
                ],
            },
            {
                pricing_textEN: "Property",
                pricing_img: pricing_property,
                text_bodyEN: "We offer buildings for sale",
                text_bodyTR: "Satılık binalar sunuyoruz",
                text_bodyRU: "Мы предлагаем здания на продажу",
                link: "/",
            },
        ],
    },
    user_comments: [
        {
            titleEN: "It’s just incredible!",
            titleRU: "Это просто невероятно!",
            titleTR: "Bu sadece inanılmaz!",
            bodyEN: `It’s just 1 month since I’m using Spend.In to
            manage my business expenses, but the result is
            very satisfying! My business finance now more
            neat than before, thanks to Spend.In!`,
            bodyRU: `Прошел всего 1 месяц с тех пор, как я пользуюсь Spend.Чтобы
            управлять своими деловыми расходами, но результат
            меня очень радует! Мой бизнес финансируется сейчас больше
                        аккуратнее, чем раньше, благодаря Spend.In !`,
            bodyTR: `Kullandığımdan bu yana sadece 1 ay geçti Spend.In için
            işletme giderlerimi yönetin, ancak sonuç
            çok tatmin edici! İşletme finansmanım artık daha fazla
            eskisinden daha temiz, teşekkürler Spend.In !`,
            usernameEN: "Jimmy Bartney",
            usernameTR: "Jimmy Bartney'in",
            usernameRU: "Джимми Бартни",
            jobEN: "Product Manager at Picko Lab",
            jobRU: "Менеджер по продуктам в Pico Lab",
            jobTR: "Pico Lab şirketinde Ürün Müdürü",
            avatar,
        },
        {
            titleEN: "No doubt, Spend.In is the best!",
            titleRU: "Не сомневайтесь, тратьте.In - это самое лучшее!",
            titleTR: "Şüphesiz, Spend.In en iyisi bu!",
            bodyEN: `  “The best”! That’s what I want to say to this
            platform, didn’t know that there’s a platform to
            help you manage your business expenses like
            this! Very recommended to you who have a big
            business!`,
            bodyTR: `"En iyisi"! Ben de bunu söylemek istiyorum.
            platform, bir platform olduğunu bilmiyordum
            aşağıdaki gibi işletme giderlerinizi yönetmenize yardımcı olun
            bu! Çok büyük olan size tavsiye edilir`,
            bodyRU: `“Самый лучший”! Вот что я хочу сказать по этому поводу
            платформа, не знал, что существует платформа
, которая поможет вам управлять вашими бизнес-расходами, например
            это! Очень рекомендую вам, у кого большой`,
            usernameEN: "Jimmy Bartney",
            usernameTR: "Jimmy Bartney'in",
            usernameRU: "Джимми Бартни",
            jobEN: "Product Manager at Picko Lab",
            jobRU: "Менеджер по продуктам в Pico Lab",
            jobTR: "Pico Lab şirketinde Ürün Müdürü",
            avatar,
        },
        {
            titleEN: "Satisfied User Here!",
            titleRU: "Довольный пользователь Здесь!",
            titleTR: "Memnun Kullanıcı Burada!",
            bodyEN: ` Never thought that with Spend.In managing my
            business expenses is so easy! Been using this
            platform for 3 months and still counting!`,
            bodyRU: `Никогда не думал, что с тратами так получится.Управлять своими
            деловыми расходами так просто! Пользуюсь этой
            платформой уже 3 месяца и продолжаю считать!`,
            bodyTR: `Bunu hiç düşünmemiştim Spend.In yönetmek benim
            işletme giderleri çok kolay! Bunu kullanıyordum
            3 aydır platform ve hala sayıyor!`,
            usernameEN: "Jimmy Bartney",
            usernameTR: "Jimmy Bartney'in",
            usernameRU: "Джимми Бартни",
            jobEN: "Product Manager at Picko Lab",
            jobRU: "Менеджер по продуктам в Pico Lab",
            jobTR: "Pico Lab şirketinde Ürün Müdürü",
            avatar,
        },
        {
            titleEN: "It’s just incredible!",
            titleRU: "Это просто невероятно!",
            titleTR: "Bu sadece inanılmaz!",
            bodyEN: `It’s just 1 month since I’m using Spend.In to
            manage my business expenses, but the result is
            very satisfying! My business finance now more
            neat than before, thanks to Spend.In!`,
            bodyRU: `Прошел всего 1 месяц с тех пор, как я пользуюсь Spend.Чтобы
            управлять своими деловыми расходами, но результат
            меня очень радует! Мой бизнес финансируется сейчас больше
                        аккуратнее, чем раньше, благодаря Spend.In !`,
            bodyTR: `Kullandığımdan bu yana sadece 1 ay geçti Spend.In için
            işletme giderlerimi yönetin, ancak sonuç
            çok tatmin edici! İşletme finansmanım artık daha fazla
            eskisinden daha temiz, teşekkürler Spend.In !`,
            usernameEN: "Jimmy Bartney",
            usernameTR: "Jimmy Bartney'in",
            usernameRU: "Джимми Бартни",
            jobEN: "Product Manager at Picko Lab",
            jobRU: "Менеджер по продуктам в Pico Lab",
            jobTR: "Pico Lab şirketinde Ürün Müdürü",
            avatar,
        },
    ],
    pricing_tarifs: [
        {
            title: "Free",
            state: false,
            descriptionEN: "Perfect plan to get started",
            descriptionRU: "Başlamak için mükemmel bir plan",
            descriptionTR: "Идеальный план для начала работы",
            tarif: { monthly: "0₽", yearly: "0₽" },
            recommedtextEN:
                "A free plan grants you access to some cool features of Spend.In.",
            recommedtextRU:
                "Бесплатный тарифный план предоставляет вам доступ к некоторым интересным функциям Spend.In.",
            recommedtextTR:
                "Ücretsiz bir plan, bazı harika özelliklere erişmenizi sağlar Spend.In .",
            recommend_options: [
                {
                    state: true,
                    option_titleEN: "Number of Users (Free)",
                    option_titleRU: "Количество пользователей (бесплатно)",
                    option_titleTR: "Kullanıcı Sayısı (Ücretsiz)",
                    option_text: "2",
                },
                {
                    state: true,
                    option_titleEN: "Number of legal entity",
                    option_titleRU: "Номер юридического лица",
                    option_titleTR: "Tüzel kişilik sayısı",
                    option_text: "1",
                },
                {
                    state: true,
                    option_titleEN: "Free Disk space (Cloud)",
                    option_titleRU: "Свободное дисковое пространство (облако)",
                    option_titleTR: "Boş Disk alanı (Bulut)",
                    option_text: "1GB",
                },
                {
                    state: true,
                    option_titleEN: "Number of projects",
                    option_titleRU: "Количество проектов",
                    option_titleTR: "Proje sayısı",
                    option_text: "1",
                },
                {
                    state: true,
                    option_titleEN: "Gantt Chart",
                    option_titleRU: "Диаграмма Ганта",
                    option_titleTR: "Gantt Grafiği",
                    option_text: "1",
                },
                {
                    state: true,
                    option_titleEN: "Cost per Extra 1 GB disk space / month",
                    option_titleRU:
                        "Стоимость дополнительного 1 ГБ дискового пространства в месяц",
                    option_titleTR:
                        "Ekstra 1 GB disk alanı / ay başına maliyet",
                    option_text: "250 RUB",
                },
                {
                    state: true,
                    option_titleEN: "In-app notifications",
                    option_titleRU: "Уведомления в приложении",
                    option_titleTR: "Uygulama içi bildirimler",
                    option_text: "",
                },
                {
                    state: true,
                    option_titleEN: "Push notifications on mobile",
                    option_titleRU: "Push-уведомления на мобильных устройствах",
                    option_titleTR: "Mobil cihazlarda push bildirimleri",
                    option_text: "",
                },
                {
                    state: true,
                    option_titleEN: "Automatic reminders",
                    option_titleRU: "Автоматические напоминания",
                    option_titleTR: "Otomatik hatırlatıcılar",
                    option_text: "",
                },
                {
                    state: true,
                    option_titleEN: "RPA: auto calculation",
                    option_titleRU: "RPA: автоматический расчет",
                    option_titleTR: "RPA: otomatik hesaplama",
                    option_text: "",
                },
            ],
            btn_text: "Get Your Free Plan",
        },
        {
            title: "Team",
            state: true,
            descriptionEN: "Perfect plan for professionals!",
            descriptionRU: "Идеальный план для профессионалов!",
            descriptionTR: "Profesyoneller için mükemmel bir plan!",
            tarif: { monthly: "19,150.00 ₽", yearly: "195,330.00₽" },
            recommedtextEN:
                "Start arranging your expenses with our best templates.",
            recommedtextRU:
                "Начните упорядочивать свои расходы с помощью наших лучших шаблонов.",
            recommedtextTR:
                "Harcamalarınızı en iyi şablonlarımızla düzenlemeye başlayın.",
            recommend_options: [
                {
                    state: true,
                    option_titleEN: "Number of Users (Free)",
                    option_titleRU: "Количество пользователей (бесплатно)",
                    option_titleTR: "Kullanıcı Sayısı (Ücretsiz)",
                    option_text: "5",
                },
                {
                    state: true,
                    option_titleEN: "Number of legal entity",
                    option_titleRU: "Номер юридического лица",
                    option_titleTR: "Tüzel kişilik sayısı",
                    option_text: "3",
                },
                {
                    state: true,
                    option_titleEN: "Free Disk space (Cloud)",
                    option_titleRU: "Свободное дисковое пространство (облако)",
                    option_titleTR: "Boş Disk alanı (Bulut)",
                    option_text: "10GB",
                },
                {
                    state: true,
                    option_titleEN: "Number of projects",
                    option_titleRU: "Количество проектов",
                    option_titleTR: "Proje sayısı",
                    option_text: "3",
                },
                {
                    state: true,
                    option_titleEN: "Admin tools",
                    option_titleRU: "Инструменты администрирования",
                    option_titleTR: "Yönetici araçları",
                    option_text: "",
                },
                {
                    state: true,
                    option_titleEN: "Documents validity check",
                    option_titleRU: "Проверка действительности документов",
                    option_titleTR: "Belgelerin geçerlilik kontrolü",
                    option_text: "",
                },
                {
                    state: true,
                    option_titleEN: "Cost per Extra 1 GB disk space / month",
                    option_titleRU:
                        "Стоимость дополнительного 1 ГБ дискового пространства в месяц",
                    option_titleTR:
                        "Ekstra 1 GB disk alanı / ay başına maliyet",
                    option_text: "100 RUB",
                },
                {
                    state: true,
                    option_titleEN: "Gantt Chart",
                    option_titleRU: "Диаграмма Ганта",
                    option_titleTR: "Gantt Grafiği",
                    option_text: "",
                },
                {
                    state: true,
                    option_titleEN: "Import from and Export to .csv / .xlsx",
                    option_titleRU: "Импорт из и экспорт в .csv / .xlsx",
                    option_titleTR: "İçe aktar ve dışa aktar.csv / .xlsx'in",
                    option_text: "",
                },
                {
                    state: true,
                    option_titleEN: "User fields in employee cards",
                    option_titleRU:
                        "Пользовательские поля в карточках сотрудников",
                    option_titleTR: "Çalışan kartlarındaki kullanıcı alanları",
                    option_text: "",
                },
                {
                    state: true,
                    option_titleEN: "Calendar",
                    option_titleRU: "Календарь",
                    option_titleTR: "Takvim",
                    option_text: "",
                },
                {
                    state: true,
                    option_titleEN: "Email alerts",
                    option_titleRU: "Оповещения по электронной почте",
                    option_titleTR: "E-posta uyarıları",
                    option_text: "",
                },
                {
                    state: true,
                    option_titleEN: "In-app notifications",
                    option_titleRU: "Уведомления в приложении",
                    option_titleTR: "Uygulama içi bildirimler",
                    option_text: "",
                },
                {
                    state: true,
                    option_titleEN: "Push notifications on mobile",
                    option_titleRU: "Push-уведомления на мобильных устройствах",
                    option_titleTR: "Mobil cihazlarda push bildirimleri",
                    option_text: "",
                },
                {
                    state: true,
                    option_titleEN: "Statistics and BI Reports",
                    option_titleRU: "Статистика и бизнес-отчеты",
                    option_titleTR: "İstatistikler ve BI Raporları",
                    option_text: "",
                },
                {
                    state: true,
                    option_titleEN: "Business Process Efficiency reports",
                    option_titleRU: "Отчеты об эффективности бизнес-процессов",
                    option_titleTR: "İş süreci verimlilik raporları",
                    option_text: "",
                },
                {
                    state: true,
                    option_titleEN: "Automatic reminders",
                    option_titleRU: "Автоматические напоминания",
                    option_titleTR: "Otomatik hatırlatıcılar",
                    option_text: "",
                },
                {
                    state: true,
                    option_titleEN: "Sending email from system",
                    option_titleRU: "Отправка электронной почты из системы",
                    option_titleTR: "Sistemden e-posta gönderme",
                    option_text: "",
                },
                {
                    state: true,
                    option_titleEN: "RPA: interrelated fields",
                    option_titleRU: "RPA: взаимосвязанные области",
                    option_titleTR: "RPA: birbiriyle ilişkili alanlar",
                    option_text: "",
                },
                {
                    state: true,
                    option_titleEN: "RPA: auto calculation",
                    option_titleRU: "RPA: автоматический расчет",
                    option_titleTR: "RPA: otomatik hesaplama",
                    option_text: "",
                },
            ],
            btn_text: "Get Started",
        },
        {
            title: "Premium",
            state: false,
            descriptionEN: "Best suits for great company!",
            descriptionRU: "Лучшие костюмы для большой компании!",
            descriptionTR: "Harika bir şirket için en uygun!",
            tarif: { monthly: "28,830.00 ₽", yearly: "294,060.00 ₽" },
            recommedtextEN:
                "If you are a finance manager at a big company, this plan is a perfect match.",
            recommedtextRU:
                "Если вы работаете финансовым менеджером в крупной компании, этот план идеально вам подойдет.",
            recommedtextTR:
                "Büyük bir şirkette finans yöneticisiyseniz, bu plan mükemmel bir eştir.",
            recommend_options: [
                {
                    state: true,
                    option_titleEN: "Number of Users (Free)",
                    option_titleRU: "Количество пользователей (бесплатно)",
                    option_titleTR: "Kullanıcı Sayısı (Ücretsiz)",
                    option_text: "10",
                },
                {
                    state: true,
                    option_titleEN: "Number of legal entity",
                    option_titleRU: "Номер юридического лица",
                    option_titleTR: "Tüzel kişilik sayısı",
                    option_text: "5",
                },
                {
                    state: true,
                    option_titleEN: "Free Disk space (Cloud)",
                    option_titleRU: "Свободное дисковое пространство (облако)",
                    option_titleTR: "Boş Disk alanı (Bulut)",
                    option_text: "20GB",
                },
                {
                    state: true,
                    option_titleEN: "Number of projects",
                    option_titleRU: "Количество проектов",
                    option_titleTR: "Proje sayısı",
                    option_text: "3",
                },
                {
                    state: true,
                    option_titleEN: "Admin tools",
                    option_titleRU: "Инструменты администрирования",
                    option_titleTR: "Yönetici araçları",
                    option_text: "",
                },
                {
                    state: true,
                    option_titleEN: "Documents validity check",
                    option_titleRU: "Проверка действительности документов",
                    option_titleTR: "Belgelerin geçerlilik kontrolü",
                    option_text: "",
                },
                {
                    state: true,
                    option_titleEN: "Cost per Extra Users / month",
                    option_titleRU:
                        "Стоимость за дополнительных пользователей в месяц",
                    option_titleTR: "Ekstra Kullanıcı Başına Maliyet / ay",
                    option_text: "1,300.00 RUB",
                },
                {
                    state: true,
                    option_titleEN: "Cost per Extra 1 GB disk space / month",
                    option_titleRU:
                        "Стоимость дополнительного 1 ГБ дискового пространства в месяц",
                    option_titleTR:
                        "Ekstra 1 GB disk alanı / ay başına maliyet",
                    option_text: "100 RUB",
                },
                {
                    state: true,
                    option_titleEN: "Gantt Chart",
                    option_titleRU: "Диаграмма Ганта",
                    option_titleTR: "Gantt Grafiği",
                    option_text: "",
                },
                {
                    state: true,
                    option_titleEN: "Kanban board",
                    option_titleRU: "Доска Канбан",
                    option_titleTR: "Kanban kurulu",
                    option_text: "",
                },
                {
                    state: true,
                    option_titleEN: "Import from and Export to .csv / .xlsx",
                    option_titleRU: "Импорт из и экспорт в .csv / .xlsx",
                    option_titleTR: "İçe aktar ve dışa aktar.csv / .xlsx'in",
                    option_text: "",
                },
                {
                    state: true,
                    option_titleEN: "User fields in employee cards",
                    option_titleRU:
                        "Пользовательские поля в карточках сотрудников",
                    option_titleTR: "Çalışan kartlarındaki kullanıcı alanları",
                    option_text: "",
                },
                {
                    state: true,
                    option_titleEN:
                        "Visual structure of a company's hierarchy (Organizational Chart)",
                    option_titleRU:
                        "Визуальная структура иерархии компании (организационная схема)",
                    option_titleTR:
                        "Bir şirketin hiyerarşisinin görsel yapısı (Organizasyon Şeması)",
                    option_text: "",
                },
                {
                    state: true,
                    option_titleEN: "Custom access roles",
                    option_titleRU: "Настраиваемые роли доступа",
                    option_titleTR: "Özel erişim rolleri",
                    option_text: "",
                },
                {
                    state: true,
                    option_titleEN: "Calendar",
                    option_titleRU: "Календарь",
                    option_titleTR: "Takvim",
                    option_text: "",
                },
                {
                    state: true,
                    option_titleEN: "Email alerts",
                    option_titleRU: "Оповещения по электронной почте",
                    option_titleTR: "E-posta uyarıları",
                    option_text: "",
                },
                {
                    state: true,
                    option_titleEN: "In-app notifications",
                    option_titleRU: "Уведомления в приложении",
                    option_titleTR: "Uygulama içi bildirimler",
                    option_text: "",
                },
                {
                    state: true,
                    option_titleEN: "Push notifications on mobile",
                    option_titleRU: "Push-уведомления на мобильных устройствах",
                    option_titleTR: "Mobil cihazlarda push bildirimleri",
                    option_text: "",
                },
                {
                    state: true,
                    option_titleEN: "Statistics and BI Reports",
                    option_titleRU: "Статистика и бизнес-отчеты",
                    option_titleTR: "İstatistikler ve BI Raporları",
                    option_text: "",
                },
                {
                    state: true,
                    option_titleEN: "Business Process Efficiency reports",
                    option_titleRU: "Отчеты об эффективности бизнес-процессов",
                    option_titleTR: "İş süreci verimlilik raporları",
                    option_text: "",
                },
                {
                    state: true,
                    option_titleEN: "Automatic reminders",
                    option_titleRU: "Автоматические напоминания",
                    option_titleTR: "Otomatik hatırlatıcılar",
                    option_text: "",
                },
                {
                    state: true,
                    option_titleEN: "Sending email from system",
                    option_titleRU: "Отправка электронной почты из системы",
                    option_titleTR: "Sistemden e-posta gönderme",
                    option_text: "",
                },
                {
                    state: true,
                    option_titleEN: "RPA: interrelated fields",
                    option_titleRU: "RPA: взаимосвязанные области",
                    option_titleTR: "RPA: birbiriyle ilişkili alanlar",
                    option_text: "",
                },
                {
                    state: true,
                    option_titleEN: "RPA: auto calculation",
                    option_titleRU: "RPA: автоматический расчет",
                    option_titleTR: "RPA: otomatik hesaplama",
                    option_text: "",
                },
                {
                    state: true,
                    option_titleEN: "Email",
                    option_titleRU: "Электронная почта",
                    option_titleTR: "E-posta",
                    option_text: "",
                },
                {
                    state: true,
                    option_titleEN: "Instant Messaging",
                    option_titleRU: "Мгновенный обмен сообщениями",
                    option_titleTR: "Anlık Mesajlaşma",
                    option_text: "",
                },
                {
                    state: true,
                    option_titleEN: "Group chats",
                    option_titleRU: "Групповые чаты",
                    option_titleTR: "Grup sohbetleri",
                    option_text: "",
                },
                {
                    state: true,
                    option_titleEN: "External access to projects",
                    option_titleRU: "Внешний доступ к проектам",
                    option_titleTR: "Projelere dışarıdan erişim",
                    option_text: "",
                },
            ],
            btn_text: "Get started",
        },
        {
            title: "Enterprise",
            state: false,
            description: "Perfect plan for professionals!",
            tarif: { monthly: "Price on demand", yearly: "Price on demand" },
            recommedtext:
                "Start arranging your expenses with our best templates.",
            recommend_options: [
                {
                    state: true,
                    option_titleEN: "Number of Users (Free)",
                    option_titleRU: "Количество пользователей (бесплатно)",
                    option_titleTR: "Kullanıcı Sayısı (Ücretsiz)",
                    option_text: "10",
                },
                {
                    state: true,
                    option_titleEN: "Number of legal entity",
                    option_titleRU: "Номер юридического лица",
                    option_titleTR: "Tüzel kişilik sayısı",
                    option_text: "5",
                },
                {
                    state: true,
                    option_titleEN: "Free Disk space (Cloud)",
                    option_titleRU: "Свободное дисковое пространство (облако)",
                    option_titleTR: "Boş Disk alanı (Bulut)",
                    option_text: "20GB",
                },
                {
                    state: true,
                    option_titleEN: "Number of projects",
                    option_titleRU: "Количество проектов",
                    option_titleTR: "Proje sayısı",
                    option_text: "3",
                },
                {
                    state: true,
                    option_titleEN: "Admin tools",
                    option_titleRU: "Инструменты администрирования",
                    option_titleTR: "Yönetici araçları",
                    option_text: "",
                },
                {
                    state: true,
                    option_titleEN: "Documents validity check",
                    option_titleRU: "Проверка действительности документов",
                    option_titleTR: "Belgelerin geçerlilik kontrolü",
                    option_text: "",
                },
                {
                    state: true,
                    option_titleEN: "Cost per Extra Users / month",
                    option_titleRU:
                        "Стоимость за дополнительных пользователей в месяц",
                    option_titleTR: "Ekstra Kullanıcı Başına Maliyet / ay",
                    option_text: "On Demand",
                },
                {
                    state: true,
                    option_titleEN: "Cost per Extra 1 GB disk space / month",
                    option_titleRU:
                        "Стоимость дополнительного 1 ГБ дискового пространства в месяц",
                    option_titleTR:
                        "Ekstra 1 GB disk alanı / ay başına maliyet",
                    option_text: "On Demand",
                },
                {
                    state: true,
                    option_titleEN: "Gantt Chart",
                    option_titleRU: "Диаграмма Ганта",
                    option_titleTR: "Gantt Grafiği",
                    option_text: "",
                },
                {
                    state: true,
                    option_titleEN: "Kanban board",
                    option_titleRU: "Доска Канбан",
                    option_titleTR: "Kanban kurulu",
                    option_text: "",
                },
                {
                    state: true,
                    option_titleEN: "Import from and Export to .csv / .xlsx",
                    option_titleRU: "Импорт из и экспорт в .csv / .xlsx",
                    option_titleTR: "İçe aktar ve dışa aktar.csv / .xlsx'in",
                    option_text: "",
                },
                {
                    state: true,
                    option_titleEN: "User fields in employee cards",
                    option_titleRU:
                        "Пользовательские поля в карточках сотрудников",
                    option_titleTR: "Çalışan kartlarındaki kullanıcı alanları",
                    option_text: "",
                },
                {
                    state: true,
                    option_titleEN:
                        "Visual structure of a company's hierarchy (Organizational Chart)",
                    option_titleRU:
                        "Визуальная структура иерархии компании (организационная схема)",
                    option_titleTR:
                        "Bir şirketin hiyerarşisinin görsel yapısı (Organizasyon Şeması)",
                    option_text: "",
                },
                {
                    state: true,
                    option_titleEN: "Custom access roles",
                    option_titleRU: "Настраиваемые роли доступа",
                    option_titleTR: "Özel erişim rolleri",
                    option_text: "",
                },
                {
                    state: true,
                    option_titleEN: "Calendar",
                    option_titleRU: "Календарь",
                    option_titleTR: "Takvim",
                    option_text: "",
                },
                {
                    state: true,
                    option_titleEN: "Email alerts",
                    option_titleRU: "Оповещения по электронной почте",
                    option_titleTR: "E-posta uyarıları",
                    option_text: "",
                },
                {
                    state: true,
                    option_titleEN: "In-app notifications",
                    option_titleRU: "Уведомления в приложении",
                    option_titleTR: "Uygulama içi bildirimler",
                    option_text: "",
                },
                {
                    state: true,
                    option_titleEN: "Push notifications on mobile",
                    option_titleRU: "Push-уведомления на мобильных устройствах",
                    option_titleTR: "Mobil cihazlarda push bildirimleri",
                    option_text: "",
                },
                {
                    state: true,
                    option_titleEN: "Statistics and BI Reports",
                    option_titleRU: "Статистика и бизнес-отчеты",
                    option_titleTR: "İstatistikler ve BI Raporları",
                    option_text: "",
                },
                {
                    state: true,
                    option_titleEN: "Business Process Efficiency reports",
                    option_titleRU: "Отчеты об эффективности бизнес-процессов",
                    option_titleTR: "İş süreci verimlilik raporları",
                    option_text: "",
                },
                {
                    state: true,
                    option_titleEN: "Automatic reminders",
                    option_titleRU: "Автоматические напоминания",
                    option_titleTR: "Otomatik hatırlatıcılar",
                    option_text: "",
                },
                {
                    state: true,
                    option_titleEN: "Sending email from system",
                    option_titleRU: "Отправка электронной почты из системы",
                    option_titleTR: "Sistemden e-posta gönderme",
                    option_text: "",
                },
                {
                    state: true,
                    option_titleEN: "RPA: interrelated fields",
                    option_titleRU: "RPA: взаимосвязанные области",
                    option_titleTR: "RPA: birbiriyle ilişkili alanlar",
                    option_text: "",
                },
                {
                    state: true,
                    option_titleEN: "RPA: auto calculation",
                    option_titleRU: "RPA: автоматический расчет",
                    option_titleTR: "RPA: otomatik hesaplama",
                    option_text: "",
                },
                {
                    state: true,
                    option_titleEN: "Email",
                    option_titleRU: "Электронная почта",
                    option_titleTR: "E-posta",
                    option_text: "",
                },
                {
                    state: true,
                    option_titleEN:
                        "External File Storage: Google Drive, OneDrive, Dropbox, Box, Yandex.Disk",
                    option_titleRU:
                        "Внешнее хранилище файлов: Google Drive, OneDrive, Dropbox, Бокс, Яндекс.Диск",
                    option_titleTR:
                        "Harici Dosya Depolama: Google Drive, OneDrive, Dropbox, Kutu, Yandex.Disk",
                    option_text: "On Demand",
                },
                {
                    state: true,
                    option_titleEN: "Instant Messaging",
                    option_titleRU: "Мгновенный обмен сообщениями",
                    option_titleTR: "Anlık Mesajlaşma",
                    option_text: "",
                },
                {
                    state: true,
                    option_titleEN: "Group chats",
                    option_titleRU: "Групповые чаты",
                    option_titleTR: "Grup sohbetleri",
                    option_text: "",
                },
                {
                    state: true,
                    option_titleEN: "API",
                    option_titleRU: "интерфейс прикладного программирования",
                    option_titleTR: "API",
                    option_text: "On Demand",
                },
                {
                    state: true,
                    option_titleEN: "Webhooks",
                    option_titleRU: "Веб-крючки",
                    option_titleTR: "Web Kancaları",
                    option_text: "On Demand",
                },
                {
                    state: true,
                    option_titleEN: "Webhooks",
                    option_titleRU: "Веб-крючки",
                    option_titleTR: "Web Kancaları",
                    option_text: "On Demand",
                },
                {
                    state: true,
                    option_titleEN: "Wiki (knowledge base)",
                    option_titleRU: "Вики (база знаний)",
                    option_titleTR: "Wiki (bilgi bankası)",
                    option_text: "",
                },
                {
                    state: true,
                    option_titleEN: "External access to projects",
                    option_titleRU: "Внешний доступ к проектам",
                    option_titleTR: "Projelere dışarıdan erişim",
                    option_text: "",
                },
                {
                    state: true,
                    option_titleEN: "Secure access for counterparties",
                    option_titleRU: "Безопасный доступ для контрагентов",
                    option_titleTR: "Karşı taraflar için güvenli erişim",
                    option_text: "",
                },
                {
                    state: true,
                    option_titleEN: "Dedicated server",
                    option_titleRU: "Выделенный сервер",
                    option_titleTR: "Özel sunucu",
                    option_text: "On Demand",
                },
                {
                    state: true,
                    option_titleEN: "Own domain",
                    option_titleRU: "Kendi alan adı",
                    option_titleTR: "Собственный домен",
                    option_text: "On Demand",
                },
                {
                    state: true,
                    option_titleEN: "Backup to client server",
                    option_titleRU: "Резервное копирование на клиент-сервер",
                    option_titleTR: "İstemci sunucuya yedekleme",
                    option_text: "On Demand",
                },
                {
                    state: true,
                    option_titleEN: "IP access filtering",
                    option_titleRU: "Фильтрация IP-доступа",
                    option_titleTR: "IP erişim filtrelemesi",
                    option_text: "On Demand",
                },
                {
                    state: true,
                    option_titleEN: "Access via VPN",
                    option_titleRU: "Доступ через VPN",
                    option_titleTR: "VPN üzerinden erişim",
                    option_text: "On Demand",
                },
                {
                    state: true,
                    option_titleEN: "Rebranding the app for the client",
                    option_titleRU: "Ребрендинг приложения для клиента",
                    option_titleTR:
                        "Uygulamayı müşteri için yeniden markalamak",
                    option_text: "On Demand",
                },
                {
                    state: true,
                    option_titleEN: "Custom modifications for the client",
                    option_titleRU: "Индивидуальные модификации для клиента",
                    option_titleTR: "İstemci için özel değişiklikler",
                    option_text: "On Demand",
                },
            ],
            btn_text: "Get Started",
        },
    ],
    footer: {
        bottom: [
            {
                optionEN: "Privacy Policy",
                optionRU: "Политика Конфиденциальности",
                optionTR: "Gizlilik Politikası",
            },
            {
                optionEN: "Terms & Conditions",
                optionRU: "Правила И Условия",
                optionTR: "Sartlar Ve Koşullar",
            },
            {
                optionEN: "Cookie Policy",
                optionRU: "Политика cookie",
                optionTR: "Çerez Politikası",
            },
        ],
        footer_column_li: [
            {
                headingEN: "Product",
                headingRU: "Продукт",
                headingTR: "Ürün",
                options: [
                    {
                        optionEN: "Workforce",
                        route: "/pricing/pricing-company",
                    },
                    {
                        optionEN: "Property",
                        route: "/",
                    },
                    // {
                    //     optionEN: "Reimbursements",
                    //     optionTR: "Geri ödemeler",
                    //     optionRU: "Возмещение расходов"
                    // },
                    // {
                    //     optionEN: "Virtual Assistant",
                    //     optionTR: "Sanal Asistan",
                    //     optionRU: "Виртуальный ассистент"
                    // },
                    // {
                    //     optionEN: "Artificial Intelligence",
                    //     optionTR: "Yapay Zeka",
                    //     optionRU: "искусственный интеллект"
                    // },
                ],
            },
            {
                headingEN: "Company",
                headingTR: "Şirket",
                headingRU: "Компания",
                options: [
                    {
                        optionEN: "About Us",
                        optionTR: "Hakkımızda",
                        optionRU: "O Hас",
                    },
                    // {
                    //     optionEN: "Newsletters",
                    //     optionTR: "Haber Bültenleri",
                    //     optionRU: "Информационные бюллетени"
                    // },
                    // {
                    //     optionEN: "Our Partners",
                    //     optionTR: "Ortaklarımız",
                    //     optionRU: "Наши партнеры"
                    // },
                    // {
                    //     optionEN: "Virtual Assistant",
                    //     optionTR: "Sanal Asistan",
                    //     optionRU: "Виртуальный ассистент"
                    // },
                    // {
                    //     optionEN: "Career",
                    //     optionTR: "Kariyer",
                    //     optionRU: "Карьера"
                    // },
                    {
                        optionEN: "Contact Us",
                        optionTR: "Bizimle Iletişime Geçin",
                        optionRU: "Связаться С Нами",
                    },
                ],
            },
            // {
            //     headingEN: "Resources",
            //     headingRU: "Ресурсы",
            //     headingTR: "Kaynaklar",
            //     options: [
            //         {
            //             optionEN: "Blog",
            //             optionTR: "Blog",
            //             optionRU: "Блог"
            //         },
            //         {
            //             optionEN: "Pricing",
            //             optionTR: "Fiyatlandırma",
            //             optionRU: "Ценообразование"
            //         },
            //         {
            //             optionEN: "FAQ",
            //             optionTR: "SSS",
            //             optionRU: "ЧЗВ"
            //         },
            //         {
            //             optionEN: "Events",
            //             optionTR: "Etkinlikler",
            //             optionRU: "События"
            //         },
            //         {
            //             optionEN: "Ebook & Guide",
            //             optionTR: "E-Kitap ve Rehber",
            //             optionRU: "Электронная книга и путеводитель"
            //         },
            //     ]
            // },
        ],
        footer_icons: [
            {
                icon: <BsLinkedin />,
                link: "https://www.linkedin.com/company/105528760/admin/dashboard/",
                class: "footer__linedin_icon",
            },
            {
                icon: <FaYoutube />,
                link: "https://www.youtube.com/@Plain2Do",
                class: "footer__youtube_icon",
            },
            {
                icon: <GrInstagram />,
                link: "https://www.instagram.com/plain2do/profilecard/?igsh=MmhucGdjYnpubG94",
                class: "footer__instagram_icon",
            },
            {
                icon: <FaTelegram />,
                link: "https://t.me/plain2do",
                class: "footer__linedin_icon",
            },
        ],
    },
};

import Hero from "../../components/Hero/Hero.jsx";
import Benefit from "../../components/Benefits/Benefit.jsx";
import How from "../../components/How/How.jsx";
import Success from "../../components/Success/Success.jsx";
import Testimonial from "../../components/Testimonial/Testimonial.jsx";
// import Pricing from "../../components/Pricing/Pricing.jsx";
import Footer from "../../components/Footer/Footer.jsx";
import { BackToTop } from "../../components/utils/index.js";
const Home = () => {
    return (
        <>
            <Hero />
            <Benefit />
            <How />
            <Success />
            <Testimonial />
            <Footer />
            <BackToTop />
        </>
    );
};

export default Home;

import { FaAngleUp } from "react-icons/fa6";
import "./index.css";
const BackToTop = () => {
    return (
        <div className="back-to-top-box">
            <div
                onClick={() => {
                    window.scrollTo({ top: 0 });
                }}
            >
                <FaAngleUp />
            </div>
        </div>
    );
};

export { BackToTop };
